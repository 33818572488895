import { render, staticRenderFns } from "./Camera.vue?vue&type=template&id=3682f87a&scoped=true&"
import script from "./Camera.vue?vue&type=script&lang=js&"
export * from "./Camera.vue?vue&type=script&lang=js&"
import style0 from "./Camera.vue?vue&type=style&index=0&id=3682f87a&scoped=true&lang=scss&"
import style1 from "./Camera.vue?vue&type=style&index=1&id=3682f87a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3682f87a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBottomSheet,VBtn,VResponsive,VSheet,VSnackbar})
