
<template>
  <v-app>
    <v-main>
      <Camera :submitUrl="submitAPI"></Camera>
    </v-main>
  </v-app>
</template>
<style>
html,body{
  height:100%;
}
#app{
  background:#282828;
  height: 100%;
  overflow: hidden;
}
</style>
<script>
import Camera from './components/Camera';

export default {
  name: 'App',
  metaInfo: {
    title: '',
    meta: [
      {charset: 'utf-8' },
      {name:"screen-orientation",content:"portrait"},
      {name:"x5-orientation",content:"portrait"},
    ]
  },
  components: {
    Camera: Camera
  },
  data: () => ({
    submitAPI: 'https://identify.outin.cn/api/query'
    // submitAPI: 'http://192.168.0.165:32149/api/query'
  }),
  methods:{

  }
};
</script>
